/// <reference path="../typings/linqjs/linq3.d.ts" />
// This script makes all javascript arrays implement IEnumerable, so that linq.js can directly be used on them, instead of having to type Enumerable.from(myArray)
// Some methods are not implemented because they already exist in standard javascript (possibly with a different meaning), or because they are not so useful (feel free to add those you need)

interface Array<T> extends linqjs.IEnumerableArray<T>
{
}

Array.prototype.contains = function (...p: any[]) {
    var enumerable = Enumerable.from(this);
    return enumerable.contains.apply(enumerable, p);
}

Array.prototype.select = function (...p: any[]) {
    var enumerable = Enumerable.from(this);
    return enumerable.select.apply(enumerable, p);
}

Array.prototype.selectMany = function (...p: any[]) {
    var enumerable = Enumerable.from(this);
    return enumerable.selectMany.apply(enumerable, p);
}

Array.prototype.where = function (...p: any[]) {
    var enumerable = Enumerable.from(this);
    return enumerable.where.apply(enumerable, p);
}

Array.prototype.ofType = function (...p: any[]) {
    var enumerable = Enumerable.from(this);
    return enumerable.ofType.apply(enumerable, p);
}

Array.prototype.all = function (...p: any[]) {
    var enumerable = Enumerable.from(this);
    return enumerable.all.apply(enumerable, p);
}

Array.prototype.any = function (...p: any[]) {
    var enumerable = Enumerable.from(this);
    return enumerable.any.apply(enumerable, p);
}

Array.prototype.defaultIfEmpty = function (...p: any[]) {
    var enumerable = Enumerable.from(this);
    return enumerable.defaultIfEmpty.apply(enumerable, p);
}

Array.prototype.distinct = function (...p: any[]) {
    var enumerable = Enumerable.from(this);
    return enumerable.distinct.apply(enumerable, p);
}

Array.prototype.orderBy = function (...p: any[]) {
    var enumerable = Enumerable.from(this);
    return enumerable.orderBy.apply(enumerable, p);
}

Array.prototype.orderByDescending = function (...p: any[]) {
    var enumerable = Enumerable.from(this);
    return enumerable.orderByDescending.apply(enumerable, p);
}

Array.prototype.groupBy = function (...p: any[]) {
    var enumerable = Enumerable.from(this);
    return enumerable.groupBy.apply(enumerable, p);
}

Array.prototype.average = function (...p: any[]) {
    var enumerable = Enumerable.from(this);
    return enumerable.average.apply(enumerable, p);
}

Array.prototype.sum = function (...p: any[]) {
    var enumerable = Enumerable.from(this);
    return enumerable.sum.apply(enumerable, p);
}

Array.prototype.count = function (...p: any[]) {
    var enumerable = Enumerable.from(this);
    return enumerable.count.apply(enumerable, p);
}

Array.prototype.max = function (...p: any[]) {
    var enumerable = Enumerable.from(this);
    return enumerable.max.apply(enumerable, p);
}

Array.prototype.min = function (...p: any[]) {
    var enumerable = Enumerable.from(this);
    return enumerable.min.apply(enumerable, p);
}

Array.prototype.elementAt = function (...p: any[]) {
    var enumerable = Enumerable.from(this);
    return enumerable.elementAt.apply(enumerable, p);
}

Array.prototype.elementAtOrDefault = function (...p: any[]) {
    var enumerable = Enumerable.from(this);
    return enumerable.elementAtOrDefault.apply(enumerable, p);
}

Array.prototype.first = function (...p: any[]) {
    var enumerable = Enumerable.from(this);
    return enumerable.first.apply(enumerable, p);
}

Array.prototype.firstOrDefault = function (...p: any[]) {
    var enumerable = Enumerable.from(this);
    return enumerable.firstOrDefault.apply(enumerable, p);
}

Array.prototype.last = function (...p: any[]) {
    var enumerable = Enumerable.from(this);
    return enumerable.last.apply(enumerable, p);
}

Array.prototype.lastOrDefault = function (...p: any[]) {
    var enumerable = Enumerable.from(this);
    return enumerable.lastOrDefault.apply(enumerable, p);
}

Array.prototype.single = function (...p: any[]) {
    var enumerable = Enumerable.from(this);
    return enumerable.single.apply(enumerable, p);
}

Array.prototype.singleOrDefault = function (...p: any[]) {
    var enumerable = Enumerable.from(this);
    return enumerable.singleOrDefault.apply(enumerable, p);
}

Array.prototype.skip = function (...p: any[]) {
    var enumerable = Enumerable.from(this);
    return enumerable.skip.apply(enumerable, p);
}

Array.prototype.take = function (...p: any[]) {
    var enumerable = Enumerable.from(this);
    return enumerable.take.apply(enumerable, p);
}

Array.prototype.toArray = function (...p: any[]) {
    var enumerable = Enumerable.from(this);
    return enumerable.toArray.apply(enumerable, p);
}

Array.prototype.cast = function (...p: any[]) {
    var enumerable = Enumerable.from(this);
    return enumerable.cast.apply(enumerable, p);
}

Array.prototype.toDictionary = function (...p: any[]) {
    var enumerable = Enumerable.from(this);
    return enumerable.toDictionary.apply(enumerable, p);
}
